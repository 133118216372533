.collapse-container {
	margin-bottom: 1rem;
	button {
		font-weight: 500;
		width: 100%;
		align-items: stretch;
		text-align: left;
		text-transform:initial;
		font-size: $fs-p;
		&, .collapse-icon {
			display: flex;
		}
		.collapse-icon {
			justify-content: center;
			align-items: center;
			padding-right: 1rem;
			border-right: 1px solid var(--second-dark);
			margin-right: 1rem;
			font-size: $fs-p;
			color: var(--blue);

			&.less {
				display: none;
			}
		}

		&[aria-expanded=true] {
			background-color: var(--violet);
			color: var(--white);
			.collapse-icon {
				color: var(--white);
				&.less {
					display: flex;
				}
				&.more {
					display: none;
				}
			}
		}
	}
}
