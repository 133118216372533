@import "./homepage";
@import "./treatment";
@import "./contact";
@import "./account";
@import "./cart";

.block-intro.homepage {
    order: 0;

    ~.strengths-section {
        order: -1;
    }
}

.block-intro {

    &.homepage,
    &.clinics {
        @include media-breakpoint-down(lg) {
            overflow: hidden;
        }
    }
}

.block-intro {

    &.intro-technology,
    &.intro-treatment {
        @include media-breakpoint-down(sm) {
            .row {
                flex-direction: column-reverse;
            }
        }
    }
}


/**
 * Intro section
 */
.homepage,
.clinics {
    .block-intro-image {
        position: relative;
        @include box(100%, calc(100% - 2rem));
        padding-bottom: 50%;
        border: 8px solid var(--violet);
        margin-right: auto;
        margin-left: auto;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 120%;
            height: 100%;
            background-position: left, bottom;
            background-size: cover;
            background-repeat: no-repeat;
            transform: translate(10%, 0%);
        }

        @include media-breakpoint-up(lg) {
			@include box(rem-calc(400));
            padding-bottom: 0;
        }
		@include media-breakpoint-up(xl) {
			@include box(rem-calc(480));
		}
		@media screen and (min-width: 1500px) {
			@include box(rem-calc(520));
			margin-right: 2rem;
		}

        @include media-breakpoint-down(sm) {
            padding-bottom: 80%;
            height: auto;

            &:before {
                width: 150%;
                transform: translate(10%, -5%);
            }
        }

    }
}

// Shared styles
.testimonials {
    @include pad-y(5rem);
    position: relative;
    &-picture img {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
    }
    &--alt {
        background-color: var( --second );
        .testimonials-carousel {
            max-width: rem-calc( 1200 );
            margin-left: auto;
            margin-right: auto;
        }
    }
}



// jobs
    // widget ATS
    // Utilisation des important pour ré-écrire le css du widget
    #ats-offers {
        *:not( .material-symbols-outlined ) {
            font-family: $ff-main, $ff-sans-serif !important;
        }
        .atom-select {
            max-width: rem-calc( 190 );
            padding-right: 2rem;
            background-position: calc( 100% - 5px ) calc( 50% + 2px );
            text-overflow: ellipsis;
            color: #212529;
            // border: 1px solid var(--black--alt);
        }
        .organism-detail-content .detail-content-title-container {
            //TODO: dans leur widget h1 devient h3 à partir du 16 sept. 2024 ( donc supprimer la régle pour le h1 quand cette modif soit en ligne )
            h1, h3 {
                color: var(--violet);
                word-break: break-word;
                text-transform: uppercase;
                letter-spacing: 0;
                @include media-breakpoint-up( md ) {
                    font-size: 2.25rem;
                }
            }
        }
        .offers-container .molecule-offer-card {
            border: none;
            background-color: rgba(235, 236, 238, 0.6);
            border-radius: 0;
            height: 100%;
            //TODO: dans leur widget h1 devient h3 à partir du 16 sept. 2024 ( donc supprimer la régle pour le h1 quand cette modif soit en ligne )
            h1, h3 {
                color: var( --gray-dark );
                font-size: $fs-default;
                font-weight: bold;
                height: auto;
                text-wrap: balance;
                word-wrap: break-word;
                text-transform: uppercase;
                letter-spacing: 0;
                margin-bottom: 0;

            }
            .card-content-container {
                .infos-container .card-info .material-symbols-outlined {
                     color: var(--violet);
                     font-size: 16px;
                 }
                 .image-container {
                    height: auto;
                    img {
                        height: 23px;
                        width: auto;
                        aspect-ratio: 3/1;
                    }
                 }

            }
        }


        .detail-content-description {
            ul {
                list-style: initial;
            }
            li {
                margin-bottom: 5px;
                &::marker {
                    color: var(--dark-gray);
                }
            }

        }
        .detail-footer-apply button.atom-button-green {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            font-size: 0.875rem;
            border: 0.0625rem solid transparent;
            line-height: 1.75;
            text-transform: uppercase;
            letter-spacing: 1px;
            background-color: var(--violet);
            color: var(--white);
            border-radius: 0;
        }
        @include media-breakpoint-down( sm ) {
            .atom-select {
                max-width: rem-calc( 180 );
            }
            .organism-list-filters {
                gap: 0;
            }

            // Offer detail
            .page-offers-detail {
                .offer-detail-article .page-offers-detail-body {
                    width: auto;
                    max-width: 100%;
                    padding-left: 15px;
                    padding-right: 15px;
                }
                .organism-detail-content .detail-content-title-container {
                    justify-content: flex-start;
                }
                .organism-detail-content .detail-content-title-container {
                    //TODO: dans leur widget h1 devient h3 à partir du 16 sept. 2024 ( donc supprimer la régle pour le h1 quand cette modif soit en ligne )
                    h1, h3 {
                        font-size: 2rem;
                    }
                }
                .detail-content-description ul {
                    padding-left: 28px;
                }
            }
        }
    }

.image-in-middle-column {
    .column {
        margin-bottom: 25px;
        &--left {

            @include media-breakpoint-up( lg ) {
                text-align: right;
            }
        }
        @include media-breakpoint-down( sm ) {
            &--middle {
                text-align: center;
                order: -1;
            }
        }
        &__title {
            font-size: $fs-xl;
            color: $second;

            @include media-breakpoint-up( lg ) {
                font-size: $fs-xxl;
            }
            @include media-breakpoint-up( xl ) {
                font-size: $fs-xxxl;
            }
        }
    }
}

.spacing.sideblocks-section {
    @include media-breakpoint-down( sm ) {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}
.row {
    &.reverse {
      flex-direction: row !important;
      &--mobile {
        @include media-breakpoint-down( sm ) {
          flex-direction: row-reverse !important;
        }
      }
      &--desktop {
        @include media-breakpoint-up( sm ) {
          flex-direction: row-reverse !important;
        }
      }
    }
  }

  .container.max-1450 {
    @include media-breakpoint-up( xxl ) {
      max-width: rem-calc( 1450 );
    }
  }

.page-show,
.page-show.dynamic-blocks {
    .engagement-list li:not(:last-child) {
        margin-bottom: 10px;
    }

    @include media-breakpoint-up( lg ) {
        .title-container {
            &.text-left {
                margin-left: 0;
            }
            .middle-line-left{
                display: inline-flex;
                align-items: center;
                hyphens: auto;
                &:before {
                    position: static;
                    width: 90px;
                    margin-right: 16px;
                    flex: none;
                }

            }
        }

    }

    &.page-monthly-payments {
        @include media-breakpoint-down( xs ) {
            .btn-violet, a.btn-violet {
                max-width: 85%;
            }
        }
        @media screen and ( min-width: 376px ) and ( max-width: 535.99px ) {
            .btn-violet, a.btn-violet {
                max-width: 80%;
            }
        }
    }
}
.page-show.dynamic-blocks {
    @include media-breakpoint-down( md ) {
        padding-top: rem-calc(60); //Header height
    }
}
.dynamic-blocks {
    .dynamic-content {
        margin-bottom: 0;
        + * {
            margin-top: 3rem;
        }
    }

    .counter-section {
        .row {
            @include pad-y(3rem);

            @include media-breakpoint-up(lg) {
                @include pad-y(5rem);
            }

        }
    }
}
// Blocks
.banner-v2-image {
    --bg-image: var( --bg-image-responsive );
    background-image: var( --bg-image );
    overflow: hidden;
    @include media-breakpoint-up( sm ) {
        --bg-image: var( --bg-image-desktop );
    }
    &.banner-section.common-banner {
        background-position: center center;
    }
    &__content {
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        min-height: rem-calc( 560 );
        padding-bottom: 1rem;
        @include media-breakpoint-up( sm ) {
            min-height: fit-content;
            padding-bottom: 0;
        }

        a.btn-blue {
            max-width: 280px;
        }
    }

}

.block-video-section {
    @include media-breakpoint-down( sm ) {
        padding-left: 0;
        padding-right: 0;
    }
    .first-column {
        width: 100%;
        background-color: var( --blue );
        padding: 0;
        --video-aspect-ratio: 1.77777;
        @include media-breakpoint-up( sm ) {
            padding: 3%;
        }
    }
    iframe {
        width: 100%;
        height: 100%;
        object-fit: cover;
        aspect-ratio: var( --video-aspect-ratio );
    }

    .second-column {
        .translated-block {
            padding: 45px 30px 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        @include media-breakpoint-up( sm ) {
            display: flex;
            align-items: center;
            .translated-block {
                padding: 50px 40px 35px;
            }
        }

        @include media-breakpoint-up( lg ) {
            .translated-block {
                margin-left: -10%;
            }
        }
    }
}

.block-bg-blue {
    @include media-breakpoint-down( sm ) {
        .title {
            border-bottom: 1px solid var( --violet );
            padding-bottom: 15px;
            margin: 0 20px 20px;
        }
    }
}