/**
 * Vars CSS pour les couleurs
 */
:root {
    --violet: #914491; // Primary color
    --black: #2A2A2A; // Secondary color (Header bg, titres, paragraphes)
    --black--alt: #4F4F4F; // tables title + accordion title
    --blue: #28B6F6; // tertiary color
    --violet--hover: #DE62DE;
    --second: #EBECEE;
    --second-light: #CDCFDA;
	--second-dark: #ACACAC;
	--bg-services-choice: #979797;
    --gray: #D8D8D8;
    --dark-gray: #818181;
    --pink--light: #EDDFED;



    --bg-input-form: #f4f2ef;
    --breadcrumb-color: #bdb7b7;
    --bg-carousel-body-card: #e3ded8;
    --headerHeight: 110px;

    // --icon-signal-color-alt-active: #ED1C24;
    // --icon-signal-bg-alt: #999999;
}

// Vars SASS pour les couleurs
$violet: #914592; // Primary color
$black: #2A2A2A; // Secondary color (Header bg, titres, paragraphes)
$black--alt: #4F4F4F; // tables title + accordion title
$blue: #28B6F6; // tertiary color
$violet--hover: #DE62DE;
$second: #EBECEE;
$second-light: #CDCFDA;
$second-dark: #ACACAC;
$bg-services-choice: #979797;
$gray: #D8D8D8;
$dark-gray: #818181;
$pink--light: #EDDFED;
$bg-input-form: #f4f2ef;
$breadcrumb-color: #bdb7b7;
$bg-carousel-body-card: #e3ded8;

/**
 *          Nomage des vars:
 * < $fs- > pour definir les font-size
 * < $ff- > pour les font-family
 *
 */

// FONT-SIZES
$fs-h1: rem-calc(36);
$fs-h1-sm: rem-calc( 30 );
$fs-subtitle: rem-calc(24);
$footer-title: rem-calc(25);
$footer-title--sm: rem-calc(20);
$fs-menu-links: rem-calc(15);
$fs-menu-links-sm: rem-calc(12.2);
$fs-default: rem-calc(16);
$fs-p: rem-calc(18);
$fs-message-promo: rem-calc(19.2); // message promo et dropdowns page prix et réservation

$fs-banner: rem-calc(63);
$fs-h2: rem-calc(28); // Soustitre
$fs-secondary-menu: rem-calc(13); // Font size des langues du menu, menu articles du footer
$fs-caption-text: rem-calc(22);
$fs-button: rem-calc(14);
$fs-xl: rem-calc( 65 );
$fs-xxl: rem-calc( 75 );
$fs-xxxl: rem-calc( 85 );
$fs-parallax-title: rem-calc(40);



// FONT_FAMILIES
$ff-main: 'Roboto';
$ff-sans-serif: sans-serif;



// Z-INDEX
$z-negative: -1;
$z-default: auto;
$z-current: 1;
$z-super: 100;
$z-superx3: 300;
$z-superx10: 1000;
$z-superx15: 1500;
$z-superx20: 2000; // Pour le header et full screen menu