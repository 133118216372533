@include media-breakpoint-up(sm) {
    .cart-errors-container {
        padding-left: rem-calc(20);
        padding-right: rem-calc(20);
    }
    .cart-item-list, .cart-items-total {
        padding: rem-calc(20);
    }
    .cart-item-list {
        margin: rem-calc(50) auto 0;
    }
    .no-mt-child .cart-item-list {
        margin-top: 0;
    }
}

.cart-items-total > div, .cart-item {
    display: flex;
    box-shadow: 0 0 5px 0 lightgrey;
    background: var(--white);
}

.cart-item {
    position: relative;
    margin-bottom: 1rem;

    &__image {
        max-width: rem-calc(160);
        object-fit: cover;
    }
    &__content {
        padding: 15px;
    }
    &__title {
        margin-bottom: 0;
        color: var(--violet);
        text-transform: uppercase;
        font-size: $fs-menu-links;
        font-weight: 600;
    }

    &__subtitle {
        color: var(--black);
        font-size: $fs-default;
        margin-bottom: rem-calc(5);
    }

    &__description {
        font-size: $fs-menu-links;
        margin-bottom: .5rem;
    }

    &__price {
        font-size: 22px;
        font-weight: 700;

        &--initial {
            font-size: 20px;
            text-decoration: line-through;
            color: var(--dark-gray);
            line-height: normal;
        }
    }

    .cart-action {
        position: absolute;
        top: 0;
        right: 0;
        background-color: transparent;
        color: var(--white);
        padding: 5px 7px;
        transition: background-color .3s ease;
        .icon {
            @include box(rem-calc(20));
            fill: var(--red);
            stroke: var(--red);
            transition: stroke .3s ease, fill .3s ease;
        }
    }

    &:hover {
        .cart-action {
            background-color: var(--red);
            .icon {
                fill: var(--white);
                stroke: var(--white);  
            }
        }
    }


    &--alt {
        justify-content: space-between;
        padding: 10px 15px;
        .cart-item__description {
            color: var(--white);
            margin-bottom: 0;
        }
    }
}

.cart-items-total {
    margin: 0 auto;
    & > div {
        padding: rem-calc(10) rem-calc(20);
        justify-content: flex-end;
    }

    p {
        margin-bottom: 0;
        text-transform: uppercase;
        font-size: 22px;
        font-weight: 700;
    }
    .total-label {
        margin-right: 10px;
    }
}


.credit-cards-container {
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
        color: var(--white);
        font-size: $fs-default;
        margin-bottom: 0;
    }
    .icon {
        @include box(35px, 20px);
        margin-left: rem-calc(10);
    }
}

@include media-breakpoint-down(sm) {
    .cart-item {
    
        &__image {
            display: none;
        }
    }

    .cart-items-total {
        & > div {
            justify-content: space-between;
        }
    }

    .credit-cards-container {
        flex-direction: column;
        p {
            margin-bottom: rem-calc(10);
        }
    }
}