.form-animated {
  .modal & {
      padding: .5rem 3rem;

      @include media-breakpoint-down(sm) {
          padding: .5rem .5rem;
      }
  }

  *:not(.btn) {
      @include media-breakpoint-down(sm) {
          text-align: left;
      }
  }
  .title {
    text-align: center;
  }
  .form-group {
    position: relative;

    label {
      &:not(.form-check-label) {
        position: absolute;
        top: 0;
        transition: .25s ease-in;
        z-index: 1;
        padding: .75rem 1rem;
        margin-bottom: 0;
        color: var(--violet);
      }
    }

    .form-control {
	  padding: .75rem 1rem;
    z-index: 0;

      &.is-invalid {
        margin-bottom: 2rem;
      }
    }
	.form-control, .custom-select {
		height: 2.85rem;
	}
    textarea.form-control {
      padding: .8rem 1rem;
    }


    &.form-animated-focus {
      label {
        &:not(.form-check-label) {
          font-size: .65rem;

          padding: 0.075rem 1rem;

          color: var(--gray);
        }
      }
    }
  }

  &.message-bottom {
    .form-group {
      label {
        &:not(.form-check-label) {
          width: 100%;
        }
      }
    }
  }
}
