.modal {
    z-index: 2500;
	&-backdrop {
		z-index: $z-superx20;
	}
    &-header {
        padding-bottom: 0;
    }
    &-content {
        border-radius: 0;
    }
    &-content,
    &-header {
        border: none;
    }
	&.modal-blured {
		background-color: rgba(#E4E4E4, .8);
		.modal-dialog {
			max-width: 820px;
		}
		.modal-body { margin-top: 1.5rem }
		.modal-content, .modal-header {
			background: transparent;
		}

		button.close {
			position: absolute;
			right: 16px;
    		top: 16px;
			background-color: var(--white);
			width: 22px;
			height: 22px;
			opacity: 1;
			color: var(--blue);
			z-index: 1;
		}
	}
	.summary-list {
		.label-item {
			font-weight: 700;
			&:before {
				margin-right: rem-calc(5);
			}

			~ li {
				padding-left: rem-calc(15.5);
			}
		}
	}
	&-iframe {
		.modal-dialog {
			max-width: 1024px;
			
		}
	}

	@include media-breakpoint-down(sm) {
		&-dialog {
			display: flex;
			align-items: center;
			justify-content: center;
			min-height: calc(100% - (.5rem * 2));
		}
	}
}


