.main-footer {
    @include pad-y(3rem, 1.5rem);

    h2 {
        margin-bottom: 1rem;
    }

    &__row {

        &:first-child {
            position: relative;
            padding-bottom: 3rem;
        }

        .footer-title ~ span, form span {
            font-size: $footer-title;
            font-weight: 300;
        }

        .social-networks {
            @include mar-y(.5rem, 1rem);

            .btn {
                @include pad-x(.5rem);
            }
        }

        .swiss-quality {
            font-weight: 300;
            text-transform: uppercase;
            font-size: rem-calc(14);

            .icon.swiss-flag {
                @include box(.8rem);
                margin-top: -2px;
            }
        }
        .form-group {
            @include mar-y(1rem, 0);
        }

        &:last-child {
            margin-top: 2rem;
        }
    }

}

@include media-breakpoint-down(sm) {
    .main-footer {
        &__row {
            .btn-rs {
                padding: .5rem 1rem;
            }

            &:first-child,
            &:first-child>div:first-child {
                margin-top: 0;
                padding-bottom: 0;
            }
        }
        .section-separator {
            width: 100%;
            margin: 3rem auto;
        }
        &__form {
            .form {
                @include pad-y(0);

                &-group {
                    margin-top: 2rem;
                    flex-direction: column;
                    align-items: center;
                    
                    div, button {
                        width: 100%;
                    }
                    button {
                        text-align: center;
                        line-height: 2.3;
                    }
                }

                &-animated .form-group .form-control {
                    @include pad-y(.5rem);
                }
            }
        }

        &__menu {
            padding-bottom: 3rem;
            h2 {
                margin-bottom: 5px;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .main-footer {
        &__row {
            display: flex;
            justify-content: space-between;
        }

        .row-menu {
            padding-top: 3rem;
        }

        &__menu {
            padding-right: 2rem;
        }

    }
}

@media only screen and (min-device-width: 480px) and (max-device-width: 812px) and (orientation: landscape) {

    .main-footer {
        form {
            padding: 0 1rem;
        }
    }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {

    .main-footer {
        form {
            padding: 0 1rem;
        }
    }
}