.block-intro {

    .images-treatment {
        position: relative;
        img {
            width: 80%;
        }
    }

     @include media-breakpoint-down(sm) {
        .left-block-treatment {
            padding-left: 0;
            padding-top: 3rem;
         }
     }

    @include media-breakpoint-up(lg) {
        .left-block-treatment {
            ~ div {
                .title-container {
                    text-align: left;
                }
                padding-top: 3rem;
            }
        }
    }
}

.processes-section {
    .processes {
        padding-top: 1rem;

        .card {
            @include pad-y(2rem, 0);

            .process-icon {
                @include box(40%, 20%);
                margin: 0 auto;
            }

            &-text {
                font-size: 1rem;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .processes {
            @include pad-x(rem-calc(15));
          flex-direction: column;
          .card {
            @include pad-y(2rem);

            &-text {
                text-align: justify;
            }
          }

          .slick-slide {
            max-height: 100%;
            overflow: visible;
          }
          .slick-slide:not(.slick-current) {
            max-height: 1px;
            transition: max-height .3s ease;
            overflow: hidden;
          }
			.slick-prev, .slick-next {
				margin-top: 1rem;
				&, &:focus, &:hover {
					background-color: var(--violet);

					&:before {
						border-top: 2px solid var(--white);
						border-right: 2px solid var(--white);
					}
				}
			}
			.slick-prev {
				left: calc(50% - 2rem);
			}

			.slick-next {
				right: calc(50% - 2rem);
			}
        }
    }

    @media screen and (min-width: rem-calc(735)) and (max-width: rem-calc(1279.98)) {
        .processes {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;

            .card {
                width: calc(50% - .5rem);
				margin-bottom: 1rem;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        .processes {
            justify-content: space-between;

            .card {
				width: calc((100% / 4) - 1rem);

				&-body {
					padding: 1.5rem;
					h5 {
						font-size: $footer-title;
					}
				}
            }
        }
    }
}

.processes-section .processes,
.engagement-list li,
.dynamic-blocks li {
    display: flex;
}

.engagement-list li,
.dynamic-blocks li {
    position: relative;
    display: block;
    &:before {
        content: url('../../images/icons/icon-check.svg');
		position: absolute;
		@include box(1.1rem);
		left: -2rem;
		top: 2px;
	}

    @include media-breakpoint-down( sm ) {
        text-align: justify;
    }
}
.engagement-list.check-white li {
    &:before {
        content: url('../../images/icons/icon-check--white.svg');
    }
}
.engagement-list.check-violet li {
    &:before {
        content: url('../../images/icons/icon-check--violet.svg');
    }
}

.engagement-section {
	@include media-breakpoint-up(lg) {
		.title-container {
			.middle-line-left {
				&:before {
					width: 3.5rem;
					background-color: var(--gray);
					left: -4rem;
				}
			}
		}
	}
    .image-container {
        padding-left: 0;

        img {
            @include box(100%);
            object-fit: cover;
        }
    }
    .engagement-block  {
        padding: 2.5rem;
    }

    @include media-breakpoint-down(sm) {
        .row {
            flex-direction: column-reverse;
        }
    }
    @include media-breakpoint-down(md) {
        .engagement-block  {
            @include mar-x(-15px);
        }
        .image-container {
            padding-right: 0;
        }
    }
}

.engagement-list, .dynamic-blocks {
    li {
        align-items: flex-start;
        &:not(:last-child) {
            margin-bottom: 1rem;
        }
        span {
            margin-right: 5px;
        }
    }
}

@include media-breakpoint-up(lg) {
    .engagement-section {
        margin-bottom: 5rem;
        .left-block {
            position: relative;
        }

        .engagement-block {
            position: relative;
            top: 3rem;
            left: 0;
            padding: 4rem 5rem;
            width: 250%;
            z-index: 10;
            background-color: var(--second);
        }

        .image-container {
            max-height: rem-calc(500);
            img {
                object-position: center;
            }
        }
    }

    .engagement-list {
        li:not(:last-child) {
            margin-bottom: 1.5rem;
        }
    }
    .dynamic-blocks {
        li:not(:last-child) {
            margin-bottom: 10px;
        }
    }
}

.engagement-section {
    @media screen and (min-width: 992.98px) and (max-width: 1160px) {

      .engagement-block {
        width: 60vw;
      }
    }
    @media screen and (min-width: 1160.98px) and (max-width: 1539.98px) {

      .engagement-block {
        width: 40vw;
      }
    }
}