.card {
    border-radius: 0;
    border: none;

    &-promo {
        transition: height .5s ease;
        .alert {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            opacity: 0;
            visibility: hidden;
            transition: opacity .3s ease;
            font-size: $fs-secondary-menu;
            border-radius: 0;
            &.show {
                visibility: visible;
                opacity: 1;
            }
        }
        &__image {
            aspect-ratio: 16 / 9;
            object-fit: cover;
        }
        .card-title {
            text-transform: uppercase;
        }
        .card-text {
            margin-bottom: 0.5rem;
            line-height: 1.1;
            font-size: rem-calc(15);
        }
        .card-price {
            font-size: 2rem;
            font-weight: 700;
            color: var(--black);

            &--initial {
                font-size: 1.5rem;
                text-decoration: line-through;
                color: var(--dark-gray);
            }

            &-selector {
                border: 1px solid;
                width: 100%;
            }
        }

        @include media-breakpoint-up(sm) {
            &:not(:hover) {
                .card-text {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }
}