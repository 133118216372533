// Section atouts
.strengths-section {
  .container {
    position: relative;
  }

  .k-icon {
    position: absolute;
    left: -100px;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
    @media screen and (min-width: 768px) and (max-width: 812px) {
      height: 60%;
    }
    @media screen and (min-width: 812.98px) and (max-width: 1380px) {
      height: 70%;
    }
    @media screen and (min-width: 1380.98px)  {
      height: 100%;
    }
  }

  .strengths {
    padding: 2rem 0;
    flex-wrap: wrap;
    justify-content: flex-end;

    .slick {

      &-prev {
        left: calc(50% - 3rem);
        ;
      }

      &-next {
        right: calc(50% - 3rem);
        ;
      }

      &-prev,
      &-prev:hover,
      &-prev:focus,
      &-next,
      &-next:hover,
      &-next:focus {
        background-color: var(--violet);

        &:before {
          border-top: 2px solid var(--white);
          border-right: 2px solid var(--white);
        }
      }
    }
  }

  .strengths,
  .strength,
  .strength__text {
    display: flex;

  }

  .strength__text p {
    margin-bottom: 0;
    font-size: rem-calc(12);

    @include media-breakpoint-down(sm) {
      font-size: 1.2rem;
    }
  }

  &.homepage {
    @media screen and (max-width: 812px) {
      .slick-slide {
        @include pad-x(.5rem);
      }

      .slick-dots {
        bottom: -5px;
      }
    }

    .strength {
      margin-bottom: 1rem;
    }
  }

  .strength {
    position: relative;
    display: flex !important; // pour annuler pes style du slick carousel
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    @include box(rem-calc(160));
    text-align: center;
    overflow: hidden;
    background-color: rgba($second, .6);

    @media screen and (min-width: 1024px) {
      margin-left: 1rem;

      &:nth-child(1) {
        margin-left: 0rem;
      }
    }

    &__icon {
      @include box(rem-calc(100));
      display: block;
    }

    &__title {
      text-transform: uppercase;
      font-size: rem-calc(15);
      margin-top: 1rem;
      font-weight: 400;
    }

    &__text {
      @include box(100%);
      position: absolute;
      align-items: center;
      bottom: 0;
      left: 0;
      padding: 1rem;
      transform: translateY(100%);
      background-color: var(--violet);
      color: var(--white);
      opacity: 0;
      transition: opacity .3s ease, transform .3s ease-in;
      @include media-breakpoint-down( sm ) {
        padding: 1rem 20px;
      }
      &, p, a {
        font-size: inherit;
        color: var( --white );
      }
    }

    &:hover {
      .strength__text {
        transform: translateY(0);
        opacity: 1;
      }
    }

    @media screen and (min-width: 768px) and (max-width: 1024px) {
      &__icon {
        @include box(rem-calc(70));
      }
    }

    @media screen and (min-width: 768px) and (max-width: 1023.98px) {
      @include box(rem-calc(220));
    }

    @media screen and (min-width: 1024px) and (max-width: 1125px) {
      @include box(rem-calc(250));
    }

    @media screen and (min-width: 1126px) and (max-width: 1190px) {
      @include box(rem-calc(270));
    }

    @media screen and (min-width: 1190.98px) and (max-width: 1365.98px) {
      @include box(rem-calc(295));
    }

    @media screen and (min-width: 1366px) and (max-width: 1399.98px) {
      @include box(rem-calc(248));
    }
  }


  // MEDIAQUERIES
  @include media-breakpoint-up(md) {

    .container {
      padding-top: 3rem;
    }

    &.homepage {
      .container {
        padding-bottom: 3rem;
      }
    }

    .subtitle {
      margin-bottom: 0;
      text-align: center;
    }
  }

  @include media-breakpoint-down(sm) {

    .strengths {
      padding: 0 0 1rem;
    }

    .strength {
      margin-left: 0;
      margin-bottom: 0;
      height: auto;
      padding: 3rem;

      &__icon {
        margin: 0 auto;
      }

      &__title {
        font-size: rem-calc(25);
      }
    }
  }
}


/**
 * Section pourquoi nous choisir
 */

.services-choice {

  .carousel-item,
  .accordion .collapse {
    .dynamic-content {
      h2 {
        color: var( --violet );
        @extend .subtitle;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .accordion {
      .card {
        @include mar-x(-15px);
        margin-bottom: 1rem;
        @include pad-y(0);
        @include pad-x(15px);
        background-color: var(--white);

        &.active {
          @include mar-y(15px);
          @include pad-y(15px, 0);
          background-color: var(--second);
        }

        >button {
          display: flex;
          flex-direction: column;
          align-items: center;
          @include pad-y(.8rem);
          font-weight: 500;
          transition: .3s ease;
          font-size: $fs-subtitle;

          &[aria-expanded="true"] {
            background-color: var(--violet);

            span {
              color: var(--white);

              ~span {
                font-size: 1rem;
              }
            }
          }

          span {
            ~span {
              font-size: 1rem;
            }
          }
        }

        &-body {
          position: relative;
          text-align: center;
          .container-image {
            margin-top: calc(40px + 1rem);
          }

          .list-group {
            @include mar-x(-15px);
            margin-top: 1rem;

            .service {
              display: flex;
              justify-content: space-between;
              align-items: center;
              background-color: var(--white);
              padding: .5rem 1rem;
              margin-bottom: .5rem;

              &__name {
                font-weight: 500;
              }

              &__price {
                font-weight: 700;
              }
            }
          }
        }
      }

      .genders {
        display: flex;
        justify-content: space-between;
        @include mar-x(rem-calc(-20));
        margin-top: -.5rem;
      }

      .gender {
        @include box(calc(50% - .375rem), auto);
        padding: 0;
        text-align: center;
        text-transform: capitalize;

        input {
          display: none;

          &:checked {
            ~label {
              color: var(--gray);

              &.icon-left {
                &:before {
                  border-top-color: var(--gray);
                  border-right-color: var(--gray);
                }
              }
            }
          }
        }

        &.woman,
        &.man {

          label {
            color: var(--white);
            cursor: pointer;
            margin-bottom: 0;
            text-transform: capitalize;
          }
        }

        &.woman {
          background-color: var(--violet);
        }

        &.man {
          background-color: var(--blue);
        }
      }
    }
  }
}

.gender {
  label {
    position: relative;
    background-color: inherit;
    &:after {
      content: "";
      position: absolute;
      bottom:20%;
      right: 50%;
      @include box(12px);
      opacity: 0;
      transform: rotate( 45deg ) translate(50%, 0%);
      background-color: transparent;
      transition: all .3s ease;
    }
  }

  input:checked~label,
  label:hover,
  label:active,
  label.active {
    &:after {
      bottom:0;
      opacity: 1;
      background-color: inherit;
    }
  }
}

/**
 * Section Questions et réponses
 */
.qar-section {
  .profile {
    text-align: center;
    @include pad-x(1rem);

    &,
    .rounded-circle {
      position: relative;
      display: flex;
      flex-direction: column;
    }

    &__experience,
    .rounded-circle:before,
    .years .plus {
      position: absolute;
    }

    &__experience {
      right: 50%;
      top: rem-calc(10); // 20px/2  before du .rounded-circle
      transform: translate(100%, 0);
    }

    .rounded-circle {
      @include box(rem-calc(115));
      justify-content: center;
      align-items: center;
      color: white;
      line-height: 1;
      margin: 0 auto .5rem;
      top: 2rem;

      &:before {
        content: "";
        @include box(calc(100% + 20px));
        border: 3px solid var(--violet);
        border-radius: 50%;
      }

      .years {
        font-size: 3rem;
        position: relative;

        .plus {
          left: -1.2rem;
          bottom: 0;
          font-style: normal;
          font-weight: 400;
        }

        ~span {
          font-size: 1.8rem;
        }
      }

      ~span {
        font-weight: 700;
      }
    }

    &__image {
      position: relative;
      z-index: -1;
      padding-left: 2rem;

      &:before {
        content: "";
        position: absolute;
        @include box(80%, 100%);
        border: 10px solid var(--violet);
        z-index: -2;
        top: -2rem;
        left: 0;
      }
    }

    &__info {
      width: 60%;
    }

    &__position {
      @include mar-y(1rem, 0);
    }

    &__name {
      margin-bottom: 0;
    }

    &__education {
      font-size: rem-calc(18);
    }

    &__position,
    &__name {
      text-transform: uppercase;
      font-weight: 500;
    }
  }

  .years {
    font-weight: 700;
  }

  .subtitle,
  .rounded-circle~span,
  .years .plus {
    font-size: 2rem;
  }

  h3 {
    font-weight: 300;
    margin-bottom: 1.5rem;
    font-size: $fs-subtitle;
    @include media-breakpoint-down( sm ) {
      text-align: center;
    }
  }

  .list {
    margin-bottom: 1.5rem;

    &__item {
      margin-bottom: .5rem;
      list-style: ">";
      margin-left: .5rem;
      padding-left: .5rem;
      font-weight: 500;
    }
  }

  .cta-container {
    text-align: center;
  }

  // MEDIAQUERIES
  @include media-breakpoint-down(sm) {
    .row {
      @include mar-x(0);
    }

    .profile {
      padding: 0 0 2rem 0;

      &__info {
        width: 100%;
        margin-bottom: 2rem;

        img {
          margin-left: 10%;
        }
      }

      &__image {
        padding-left: 0;
      }

      &__image:before,
      &__info img {
        width: 90%;
      }

      &__experience {
        right: 0;
        transform: translate(0, -1.5rem);
      }

      .rounded-circle {
        @include box(80px);

        .years,
        .plus {
          font-size: 1.8rem;

          ~span {
            font-size: 1.1rem;
          }
        }

        ~span {
          font-size: 1.2rem;
        }

      }
    }
  }

  @include media-breakpoint-only(md) {
    .profile {

      &__info {
        width: 80%;
      }

      .rounded-circle {
        @include box(100px);

        .years,
        .plus {
          font-size: 2rem;

          ~span {
            font-size: 1.5rem;
          }
        }

        ~span {
          font-size: 1.5rem;
        }

      }
    }
  }
}