.banner-section.homepage {
  position: relative;

  .carousel-item {
    height: 100%;
    // background-repeat: no-repeat;
    // background-size: 130%;
    // background-position: 0 50%;

    // @include media-breakpoint-down(lg) {
    //   background-size: cover;
    //   background-position: center;
    // }

    // @media screen and (max-width: 536px) and (orientation: portrait) {
    //   background-size: 250%;
    //   background-position: 55% 100%;
    // }
    picture img {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;

      @include media-breakpoint-up(md) {
        width: 130%;
      }
    }

    .container {
      margin: 0 auto;
      height: 100%;
      position: relative;
    }
  }

  padding-bottom: 0;
  max-height: 1200px;

  @media screen and (min-width: rem-calc(3000)) {
    max-height: 600px;
  }

  @include media-breakpoint-down(sm) {
    height: 85vh;
    margin-top: 3rem;
    background-color: var(--second);
    background-position: 55% 100%;
    background-size: 230%;

    >.container {
      height: calc(100% - 5vh);
    }

    .carousel.banner,
    .carousel-inner,
    .carousel-item,
    .carousel-caption {
      height: 100%;
    }
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 5rem;

    background-color: transparent;
    height: 85vh;

    >.container,
    .carousel.banner,
    .carousel-inner,
    .carousel-item {
      height: 100%;
    }
  }

  @media screen and (min-width: 1024px) and (max-width: 1480px) {
    background-size: cover;

  }

  .actions-container {
    position: relative;
    padding-bottom: 1rem;

    .social-networks {
      margin-right: 1rem;

      a[class^="btn"] {
        @include pad-x(.5rem);
      }

    }

    .scroll {
      transform: translateX(50%);
    }

    .positioned-block {
      position: absolute;
      right: 0;
      left: calc(50% - (30px + 1rem)); // 1rem margin-right dots + 30px width dots
      width: fit-content;
      align-items: center;
      z-index: 100;
      bottom: 0;
      background-color: rgba($blue, .7);
      transform: translateY(50%);

      >.middle-line {
        @include box(1px, 100px);
        background-color: var(--white);
      }

      @include media-breakpoint-only(lg) {
        left: calc(50% - (100px + 1rem)); // 1rem margin-right dots + 30px width dots
      }
    }

    a[class^="btn"] {
      line-height: 1.5;
    }

    .video,
    .booking {
      padding: 2rem;
    }

    .video {
      a:hover {
        color: var(--white);
      }

      .middle-line {
        height: 2px;
        background-color: rgba($white, .5);
        width: 60px;
        margin-left: 1.5rem;
      }
    }
  }

  @media screen and (min-width: 1024px) {

    .actions-container {
      top: -4rem;
      z-index: 20;
    }
  }
}

.carousel {
  &.banner {

    img {
      height: auto;
      object-fit: cover;
      object-position: top;
    }

    &.homepage {

      .carousel-caption {
        position: static;
        @include pad-y(20%, 25%);
        opacity: 0;
        transition: opacity 1s ease;

        >div:first-child {
          padding: rem-calc(25);
          background-color: #d1d4dd99;

          @include media-breakpoint-up(md) {
            padding: rem-calc(50);

          }
        }
      }

      .carousel-item.active {
        .carousel-caption {
          opacity: 1;
        }
      }

      @media screen and (min-width: 1024px) {
        .carousel-caption {
          position: absolute;
          bottom: 50%;
          transform: translateY(50%);
          @include pad-y(0);

          p {
            margin-bottom: 0;
          }
        }
      }

      @include media-breakpoint-down(sm) {
        img {
          object-position: 75%;

        }

        .carousel-caption {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          top: 20%;
          @include pad-y(3rem, 0);
        }
      }

    }

    .carousel-caption {
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0;
      text-align: left;

      h5 {
        position: relative;
        font-weight: 700;
        font-size: rem-calc(30);
        text-transform: uppercase;
        margin-bottom: 1rem;

        &:before {
          content: "";
          position: absolute;
          top: calc(100% + .5rem);
          left: 0;
          @include box(80%, 2px);
          background-color: var(--blue);
        }

        @include media-breakpoint-down(sm) {
          text-align: center;

          &:before {
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }

      p {
        font-weight: 300;
        font-size: $footer-title--sm;
        margin-bottom: 2rem;

        @include media-breakpoint-down(sm) {
          text-align: justify;
        }
      }
    }

    .carousel-indicators {
      bottom: calc(-3.5rem + 3px); // 3rem padding du section, + .5rem margin indicators + 3px border du carousel

      li {
        @include box(rem-calc(10));
        @include mar-x(5px);
        transform: rotate(45deg);
        background-color: var(--violet);
        box-shadow: 0 0 0 2px var(--white) inset;
        border-radius: 50%;
        opacity: 1;
      }

      .active {
        background-color: var(--blue);
      }
    }

    /*****************
        * MEDIA QUERIES *
        *****************/

    // xs to down
    @media screen and (max-width: 320px) {
      .carousel-caption {
        padding: 1rem;
      }
    }

    // small to 668px
    @media screen and (min-width: 320px) and (max-height: 812px) and (orientation: portrait) {
      img {
        height: calc(100vh - 60px - 3rem); // 60px header height, 3rem padding banner-section
      }

      &.banner-clinics,
      &.banner-reservation {
        img {
          height: auto;
          min-height: 25vh;
        }
      }
    }

    // Medium to lg
    @media screen and (min-width: 768px) and (max-width: 1023.98px) and (orientation: portrait) {
      img {
        height: 35vh;
      }

      &.banner-clinics,
      &.banner-reservation {
        img {
          height: auto;
        }
      }

      .carousel-caption {
        max-width: 100%;
        bottom: 1rem;
        left: 1rem;
        right: 1rem;
      }
    }

    // Medium to up
    @media screen and (min-width: 1024px) {

      &:not(.homepage) img {
        height: auto;
      }

      &-reservation,
      &-clinics {
        img {
          object-fit: cover;
          max-height: 470px;
          min-height: 250px;
        }
      }

      .carousel-caption {
        max-width: 40%;
        bottom: 25%;
        left: 0;

        h5 {
          font-size: rem-calc(42);

          &:before {
            width: 40%;
          }
        }

        p {
          font-size: $fs-caption-text;
        }
      }

      .carousel-indicators.homepage-carousel-indicators {
        flex-direction: column;
        left: initial;
        bottom: 50%;
        margin-bottom: 0;
        @include mar-x(0, 1rem);

        li {
          @include box(initial);
          text-indent: 0;
          transform: rotate(0);
          font-weight: 500;
          color: var(--second-dark);
          border: none;
          margin-bottom: rem-calc(10);
          box-shadow: none;
          background-color: transparent;
          font-size: rem-calc(22);

          &:after {
            position: absolute;
            content: "";
            @include box(50px, 1px);
            right: -55px;
            top: 50%;
            transform: translateY(-50%);
            background-color: var(--second-dark);
          }

          &.active {
            color: var(--violet);

            &:after {
              background-color: var(--violet);
            }
          }
        }
      }
    }

    @media screen and (min-width: 1200px) {

      &-reservation,
      &-clinics {
        img {
          min-height: 470px;
        }
      }
    }

    @media screen and (min-width: 1024px) and (max-width: 1199.98px) {

      .carousel-caption {
        max-width: 50%;
      }
    }
  }


  // Homepage->section pourquoi nous choisir (carousel)
  &-accordion {
    [class^="btn"] {
      @include pad-x(2.5rem);
    }

    @include media-breakpoint-up(md) {
      overflow: hidden;

      .gender,
      .carousel-control-prev,
      .carousel-control-next {
        display: flex;
        align-items: center;

      }

      .carousel-accordion-nav {
        position: relative;

        &:before {
          content: "";
          position: absolute;
          @include box(100%);
          left: calc(50% - 13px);
          background-color: var(--blue);
        }
      }

      .counter-slides {
        @include pad-x(2rem);
        @include pad-y(3rem, 2rem);

        span {
          position: relative;

          &#current-slide {
            &:before {
              content: "<";
              margin-right: 5px;
            }

            &,
            &~.separator-slides {
              font-size: 2.5rem;
            }
          }

          &.total-slides {
            font-size: 1.5rem;

            &:after {
              content: ">";
              margin-left: 5px;
            }
          }

          &#current-slide:before,
          &.total-slides:after {
            display: inline-block;
            font-size: rem-calc(20);
            color: var(--white);
            transform: translateY(-2px);
          }
        }
      }

      .carousel-indicators {
        display: inline-flex;
        flex-direction: column;


        &.buttons-indicator,
        &.dots-indicator {
          bottom: -3rem;
        }


        &.buttons-indicator {
          position: initial;
          margin-bottom: 3rem;
          @include pad-x(2rem);
          @include mar-x(0);

          li {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            @include box(100%, initial);
            margin: 0;
            padding-left: 5px;
            line-height: 1.1;
            opacity: 1;
            text-indent: 0;
            background-color: transparent;
            transition: color .3s ease-in, background-color .3s ease-in;

            &:before {
              content: "";
              position: absolute;
              @include box(2rem, 1px);
              top: 50%;
              left: -2rem;
              background-color: var(--gray);
            }

            &.active:before {
              background-color: var(--violet);
            }

            span {
              color: var(--gray);
              font-weight: 500;
              font-size: 1.5rem;
            }

            &.active {

              span:first-child,
              &,
              span {
                color: var(--violet);
              }

            }
          }
        }

        &.dots-indicator {
          @include mar-x(3rem);

          li {
            width: 100%;

            &.active {
              background-color: var(--blue);
            }
          }
        }
      }


      .arrows {
        display: flex;
        padding: 0 2.5rem 2rem;
      }

      .carousel-control-prev,
      .carousel-control-next {
        position: initial;
        justify-content: center;
        align-items: center;
        @include box(2rem);
        z-index: $z-super;
        top: initial;
        bottom: -3.5rem;
        background-color: var(--second);
        opacity: 1;

        &:before {
          content: "";
          position: static;
          @include box(.7rem);
          border-top: 2px solid var(--blue);
          border-right: 2px solid var(--blue);
          opacity: 1;
        }
      }


      .carousel-control-prev {
        left: 1rem;
        transform: translate(-50%, -60%);

        &:before {
          transform: rotate(-135deg);
          margin-left: 4px; // 4px à cause du border de 2px * 2
        }
      }

      .carousel-control-next {
        right: 1rem;
        transform: translate(50%, -60%);

        &:before {
          transform: rotate(45deg);
          margin-left: -4px; // 4px à cause du border de 2px * 2
        }
      }

      .carousel-inner {
        display: flex;
        align-items: center;
        @include pad-y(2rem);
      }

      // Toutes les slides sauf celle-là où il y a les coprs avec les prix
      .carousel-item.active:not(.price-slide) {
        display: flex;

        >div {
          width: 50%;
          padding-right: 2rem;
        }

        .carousel-image {
          padding: 3rem;

          svg {
            @include box(100%);
          }

          img {
            aspect-ratio: 510 / 280;
          }
        }
      }

      .price-slide {
        @include pad-x(2rem);
        padding-top: 3rem;

        #woman-price {
          position: relative;
          z-index: 1;
        }
      }

      .gender {
        position: absolute;

        input {
          display: none;

          &:checked {
            ~label {
              color: var(--gray);

              &.icon-left {
                &:before {
                  border-top-color: var(--gray);
                  border-right-color: var(--gray);
                }
              }
            }
          }
        }

        &.woman,
        &.man {
          top: 0;

          &:before {
            content: "";
            @include box(100%);
            position: absolute;
            top: 0;
            z-index: 0;
          }


          label {
            align-self: flex-end;
            position: relative;
            color: var(--white);
            cursor: pointer;
            margin-bottom: 0;
            text-transform: capitalize;
          }

        }

        &.woman {

          &,
          &:before {
            left: 0;
            background-color: var(--violet);
          }
        }

        &.man {
          justify-content: flex-end;

          &,
          &:before {
            right: 0;
            background-color: var(--blue);
          }
        }
      }
    }


    @include media-breakpoint-up(xl) {
      .price-slide {
        padding-top: 5rem;
      }
    }
  }
}


.testimonials-carousel {
  .slick-slide {
    width: 100%;
  }

  .testimonials:not(.testimonials--alt) & {

    // Slick arrows carousel
    .slick {

      &-prev {
        left: calc(50% - 2.5rem);
        transform: translate(-50%, -50%);
      }

      &-next {
        right: calc(50% - 2.5rem);
        transform: translate(50%, -50%);
      }
    }

  }

  .carousel-slide {
    position: relative;
    @include pad-x(1rem);

    .quote {
      position: absolute;
      fill: var(--violet--hover);
      fill-opacity: 0.2;
      z-index: 100;

      &--start {
        top: 1rem;
        left: 2rem;
      }

      &--end {
        bottom: 3rem;
        right: 2rem;
      }
    }
  }

  .testimonial {
    background-color: var(--violet);
    color: var(--white);
    padding: 2rem;

    p {
      font-style: italic;
      font-weight: 300;

      @include media-breakpoint-down(sm) {
        font-size: $fs-default;
      }
    }
  }

  /*****************
     * MEDIA QUERIES *
     *****************/

  // Mobile small screens
  @include media-breakpoint-down(sm) {

    .carousel-slide {

      .quote {
        @include box(rem-calc(50), rem-calc(35));
      }
    }
  }

  // Tablets screen to up
  @include media-breakpoint-up(md) {
    .carousel-slide {

      .quote {
        @include box(rem-calc(90), rem-calc(75));
      }
    }

    .testimonial {
      padding: 2rem 4rem;
    }
  }

  .slick-slider,
  .slick-list,
  .slick-track {
    display: flex;
  }

  .carousel-slide,
  .slick-slide>div,
  .testimonial {
    height: 100%;
  }

  .testimonial {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  // Desktop screen to up
  @include media-breakpoint-up(xl) {

    .testimonial {
      padding: 4rem 6rem;
    }
  }
}

.section-testimonials--alt {
  background-color: var(--second);

  .title {
    margin-bottom: 0;
  }
}

.testimonials--alt {
  padding-top: 0;
  padding-bottom: 0;

  .testimonials-carousel {
    padding-left: 25px;
    padding-right: 25px;

    .testimonial {
      position: relative;
      justify-content: flex-start;
      background-color: transparent;
      color: var(--dark);

      &:before {
        content: "\201C";
        font-size: 148px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        line-height: 1;
        color: #0000001A;
      }

      p {
        font-style: normal;
        font-weight: 400;
      }
      .stars {
        .star {
          width: auto;
          height: 28px;
          max-width: calc( 28px * 5 );
          background-image: url("~Images/icons/star.svg");
          background-repeat: space no-repeat;
          background-size: 24px;
        }
      }
    }

    .slick {

      &-prev,
      &-next {
        @include box(45px);
        transition: background-color .35s ease;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10;
        &:before {
          content: "";
          display: block;
          @include box(15px);
          border-top: 6px solid var(--white);
          border-right: 6px solid var(--white);
          transform: rotate(-135deg) translate(-10%, 10%);
          border-radius: 2px;
          transition: border-color .35s ease;
        }

        &:focus,
        &:active,
        &:hover {
          background-color: var(--blue);

          &:before {
            border-color: var(--violet);
          }
        }
      }

      &-next {
        &:before {
          transform: rotate(45deg) translate(-10%, 10%);
        }
      }
    }

    @include media-breakpoint-down(md) {
      & {
        padding-left: 0;
        padding-right: 0;
      }
      .slick {
        &-prev,
        &-next {
          position: absolute;
          top: 30px;
        }
        &-prev {
          left: -5px;
        }
        &-next {
          right: -5px;
        }
      }

      .testimonial {
        padding-left: 0;
        padding-right: 0;
        padding-top: 70px;
      }
    }
  }
}



.processes {

  // Slick arrows carousel
  .slick {

    &-dots {
      bottom: rem-calc(-45);
      left: 0;

      .slick-active button {

        &,
        &:before {
          color: var(--blue);
        }
      }

    }

    &-prev,
    &-next {
      background-color: var(--second);
      top: calc(100% + 1rem);
    }

    &-prev {
      left: calc(3.5rem - 1.5rem);
      transform: translate(-50%, 0);
    }

    &-next {
      right: calc(3.5rem - 1.5rem);
      transform: translate(50%, 0);
    }
  }
}

// Slick arrows carousel
.strengths-swiss,
.dynamic-content {
  .strengths {
    padding-bottom: 2rem;
    justify-content: center;
  }

  .strength {
    @include box(calc((1220px / 3) - 6rem - 30px));

    &:nth-child(1) {
      margin-left: 0;
    }

    &__title {
      font-size: $fs-subtitle;
      font-weight: 500;
    }

    &__text,
    &__text p {
      font-size: 1.12rem;
    }

    @media screen and (min-width: 768px) and (max-height: 1024px) and (max-width: 992px) {
      .strength__text {
        font-size: .85rem;
      }
    }

    @include media-breakpoint-down(md) {
      @include box(214px);
    }
  }
}

@include media-breakpoint-down(sm) {
  .strength {
    &__text {
      padding: 1rem 20px;
      text-align: justify;
    }
  }
}

@media screen and (min-width: rem-calc(768)) and (max-width: 812px) {

  .strengths-section:not(.homepage) {
    .strengths-carousel {
      padding: 2rem 20%;

      .strength {
        padding: 3rem;
        height: auto;
        min-height: 300px;

        h3 {
          font-size: 1.75rem;
        }

        &__text {
          font-size: 1.2rem;
        }
      }

      .strength__icon {
        width: 100%;
        height: 6.25rem;
      }
    }
  }
}

.carousel.banner,
.testimonials:not(.testimonials--alt) .testimonials-carousel,
.strengths-carousel,
.technologies-navigation,
.processes,
.carousel-promos {
  .slick {

    &-prev,
    &-next {
      display: flex;
      justify-content: center;
      align-items: center;
      @include box(2rem);
      z-index: $z-super;

      &:before {
        content: "";
        position: static;
        @include box(.7rem);
        opacity: 1;
      }
    }


    &-prev {
      &:before {
        transform: rotate(-135deg);
        margin-left: 4px; // 4px à cause du border de 2px * 2
      }
    }

    &-next {
      &:before {
        transform: rotate(45deg);
        margin-left: -4px; // 4px à cause du border de 2px * 2
      }
    }
  }
}

.carousel.banner,
.testimonials:not(.testimonials--alt) .testimonials-carousel,
.strengths-carousel,
.technologies-navigation,
.processes {
  .slick {

    &-prev,
    &-next {

      &,
      &:hover,
      &:focus {
        background: var(--white);
      }
    }

    &-prev,
    &-next {
      top: 100%;

      &:before {
        border-top: 2px solid var(--blue);
        border-right: 2px solid var(--blue);
      }
    }


    &-prev {
      &:before {
        transform: rotate(-135deg);
        margin-left: 4px; // 4px à cause du border de 2px * 2
      }
    }

    &-next {
      &:before {
        transform: rotate(45deg);
        margin-left: -4px; // 4px à cause du border de 2px * 2
      }
    }
  }
}

.strengths-booking {
  .slick {

    &-prev,
    &-next {
      top: calc(100% + 1rem);
    }
  }
}


.carousel.banner {
  .slick {

    &-prev,
    &-next {
      top: 50%;
      opacity: 1;
      transform: translateY(-50%);

      @include media-breakpoint-down(sm) {
        top: 80%;
      }
    }

    &-prev {
      left: 1rem;
    }

    &-next {
      right: 1rem;
    }
  }


}

.carousel-promos {
  @media screen and (min-width: 768px) {
    .slick-slide {
      padding-left: 15px;
      padding-right: 15px;
    }

  }

  .slick {

    &-prev,
    &-next {
      background-color: var(--violet);
      transition: opacity .3s ease;

      &:before {
        border-top: 2px solid var(--white);
        border-right: 2px solid var(--white);
      }

      &:hover {
        opacity: .85;
      }

    }
  }

  @media screen and (max-width: 536px) {
    margin-bottom: 4rem;

    .slick {

      &-prev,
      &-next {
        top: calc(100% + 2rem);
      }

      &-prev {
        left: calc(50% - 3rem);
      }

      &-next {
        right: calc(50% - 3rem);
      }
    }
  }
}


.main-header.has-banner-sales {
  ~main {

    .banner-section.homepage,
    .banner-section.common-banner,
    .section-reservation-process-header,
    .page-show .banner-secondary {
      margin-top: rem-calc(90);

      @include media-breakpoint-down(md) {
        margin-top: rem-calc(150);
      }

      @include media-breakpoint-down(sm) {
        margin-top: rem-calc(185);
      }
    }

    .page-monthly-payments .banner-secondary {
      @include media-breakpoint-down(md) {
        margin-top: rem-calc(90)
      }
    }

    .banner-section.banner-clinics,
    .banner-section.banner-reservation,
    .account-section {
      margin-top: 0;
      padding-top: rem-calc(180);

      @include media-breakpoint-down(md) {
        padding-top: rem-calc(150);
      }

      @include media-breakpoint-down(sm) {
        padding-top: rem-calc(185);
      }
    }

    .header-height-space {
      padding-top: rem-calc(210);

      @include media-breakpoint-down(md) {
        padding-top: rem-calc(200);
      }

      @include media-breakpoint-down(sm) {
        padding-top: rem-calc(220);
      }
    }
  }


  &.has-countdown {
    ~main {

      .banner-section.homepage,
      .banner-section.common-banner,
      .section-reservation-process-header {
        @include media-breakpoint-down(md) {
          margin-top: rem-calc(200);
        }

        @include media-breakpoint-down(sm) {
          margin-top: rem-calc(249);
        }
      }

      .banner-section.banner-clinics,
      .banner-section.banner-reservation,
      .account-section {
        padding-top: rem-calc(180);
        margin-top: 0;

        @include media-breakpoint-down(md) {
          padding-top: rem-calc(200);
        }

        @include media-breakpoint-down(sm) {
          padding-top: rem-calc(249);
        }
      }

      .header-height-space {
        padding-top: rem-calc(230);

        @include media-breakpoint-down(md) {
          padding-top: rem-calc(250);
        }

        @include media-breakpoint-down(sm) {
          padding-top: rem-calc(299);
        }
      }
    }

  }
}