.icon {

    &.shop-bag,
    &.swiss-flag,
    &.close-icon {
        @include box(1rem);
    }

    &.user-icon {
        @include box(.85rem);
    }

    // Close icon button
    &.close-icon {
        fill: var(--violet);
    }

    // Menu icon button
    &.burger-icon {
        stroke: var(--blue);
        @include box (rem-calc(35));
    }

    // Social network icons
    &.rs-icon {
        @include box(1.5rem);
        fill: var(--violet);
        &.fill-color {
            &--white {
                fill: #EBECEE;
            }
        }
    }

    &.check-icon,
    &.delete-icon,
    &.plus-icon,
    &.less-icon,
    &.equal-icon {
        @include box(1.1rem);
    }

    &.signal-icon {
        @include box(2rem);
    }

    &.scroll-icon {
        @include box(20px, 33px);
    }

    &.video-icon {
        @include box(50px);
        fill: var(--violet);
    }
}