.parallax {
    background-color: $violet;
    background-attachment: fixed;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    @include media-breakpoint-down( sm ) {
        background-attachment: initial;
    }
    &:before {
        content: "";
        position: absolute;
        inset: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-color: inherit;
        opacity: .75;
    }

    &__title {
        font-size: $fs-parallax-title;
        @include media-breakpoint-down( sm ) {
            font-size: $fs-h1;
        }
    }
}