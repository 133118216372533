[data-aos=fade-up] {
    transform: translate3d(0,100px,0);
    @include media-breakpoint-down( sm ) {
        transform: translate3d(0,50px,0);

    }
}
@include media-breakpoint-down( sm ) {
    [data-aos^=fade][data-aos^=fade].aos-animate.label-page {
        transform: translate(-50%, -50%);
    }
}