body {
  overflow-x: hidden;
  max-width: 3000px;
  margin: 0 auto;
  justify-content: center;

  @media screen and (min-width: 3000px) {
    position: relative;
    box-shadow: 0px 0px 50px 25px var(--gray);

    main {
      align-items: center;
      overflow: hidden;
    }

    &,
    article,
    section,
    footer {
      max-width: 1400px;
      // overflow: hidden;
    }
  }
}


::placeholder {
  opacity: 1;
}


main {
  width: 100%;
  background-color: var(--white);
}

main section:not(.banner-section) {
  z-index: 3;
}

a {
  color: var(--black);
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

svg {
  pointer-events: none;
}

.btn,
button,
input,
.form-control {
  border: none;
}

ul {
  list-style: none;
}

table,
tr,
th,
td {
  text-align: left;
}


select {
  border: none;
  padding: .5rem 1rem;
  font-family: $ff-sans-serif;
  appearance: none; // hide default arrow
  -webkit-appearance: none; // Removes default chrome and safari style
  -moz-appearance: none; // Removes default style Firefox
  background-image: url("~Images/icons/dropdown-arrow.svg"); // Arrow select
  background-repeat: no-repeat;
  background-size: 1rem;
  background-position: calc(100% - 1rem) 50%;
  height: calc(1.5em + 0.75rem + 2px); // Height des inputs
  background-color: var(--white);
  min-width: rem-calc(140);
  cursor: pointer;
}

textarea {
  min-height: rem-calc(150);
}

img,
.card-img,
.card-img-top,
select,
.btn,
button,
input,
.form-control {
  border-radius: 0;
}


[class*="cc-color-override-"].cc-window {
  position: fixed;
  top: initial;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: $z-superx20;
  padding: 1rem;
  font-family: $ff-sans-serif;
  font-size: 14px;

  .cc-link {
    transition: .3s ease;
    color: var(--white);

    &:hover {
      text-decoration: underline;
    }
  }
}

[class*="cc-color-override-"] .cc-btn {
  display: inline-block;
  color: var(--white);
  border: 1px solid var(--white);
  background-color: var(--violet);
  padding: 10px 1rem;
  transition: background-color .3s ease, border .3s ease;

  &:hover {
    background-color: var(--violet--hover) !important;
    color: var(--white) !important;
    border-color: transparent;
  }
}


@include media-breakpoint-down(sm) {

  header .container {
    &:before {
      clip-path: polygon(0 0, 0 100%, 50% 0);
    }
  }

  [class*="cc-color-override-"].cc-window {
    flex-wrap: wrap;
    justify-content: center;

    .cc-btn {
      margin-top: 1rem;
    }
  }
}
