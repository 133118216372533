@import "main-menu";
@import "footer-menu";

// Styles partagés pour les  menus 

.main-menu .nav-link,
.nav-link,
.link {
	position: relative;
	color: var(--black);
	transition: color .3s ease;

	&:hover,
	&:focus,
	&:active {
	    // transition: background-color .3s ease;
	    color: var(--violet);
	//     &:before {
	//         content: "";
	//         position: absolute;
	//         top: 50%;
	//         left: 0;
	//         transform: translate(0%, -50%);
	//         @include box(100%, 2px);
	//         background-color: var(--violet);
	//     }
	}
}

/* Miranda */
.main-menu .nav-item {
	position: relative;
	@include media-breakpoint-up(lg) {
		@include mar-x(1rem, 0);
	}
}

.main-menu .nav-link {
	position: relative;
	display: block;
	color: var(--black);
	transition: color 0.3s;
	@include media-breakpoint-up(lg) {
		text-align: center;
	}
}

.main-menu .nav-link {
	&:hover,
	&:focus {
		color: var(--violet);
	}
}

.main-menu .nav-item {

	&.active,
	&:hover {
		.nav-link {
			color: var(--violet);
		}
	}
}

.main-menu .nav-item,
.main-menu .nav-link {
	@include media-breakpoint-up(lg) {
		&:before,
		&:after {
			content: '';
			position: absolute;
			background: var(--violet);
			transition: transform 0.1s;
			transition-timing-function: cubic-bezier(1, 0.68, 0.16, 0.9);
		}
	}
}


/* left and right line */
.main-menu .nav-item {
	@include media-breakpoint-up(lg) {
		&::before,
		&::after {
			top: 0;
			width: 2px;
			height: 100%;
			transform: scale3d(1, 0, 1);
		}
	
		/* left line */
		&::before {
			left: 0;
			transform-origin: 50% 100%;
		}
	
		/* right line */
		&::after {
			right: 0;
			transform-origin: 50% 0%;
		}
	}
}

/* top and bottom line */
.main-menu .nav-link {
	@include media-breakpoint-up(lg) {
		&:before,
		&:after {
			left: 0;
			width: 100%;
			height: 2px;
			transform: scale3d(0, 1, 1);
		}
	
		/* top line */
		&:before {
			top: 0;
			transform-origin: 0 50%;
		}
	
		/* bottom line */
		&:after {
			transform-origin: 100% 50%;
			bottom: 0;
		}
	}
}


/* Delays (first reverse, then current) */

/* These rules can be simplified, but let's keep it for better readability */

/* bottom line */
.main-menu .nav-item .nav-link:after {
	transition-delay: 0.3s;
}

.main-menu .nav-item {

	&.active,
	&:hover {
		.nav-link {
			&::after {
				transition-delay: 0s;
			}
		}
	}
}


/* left line */
.main-menu .nav-item:before {
	transition-delay: 0.2s;
}

.main-menu .nav-item {

	&.active,
	&:hover {

		&:before,
		&::after {
			transition-delay: 0.1s;
		}
	}
}

/* top line */
.main-menu .nav-item .nav-link:before {
	transition-delay: 0.1s;
}

.main-menu .nav-item {

	&.active,
	&:hover {
		.nav-link {
			&::before {
				transition-delay: 0.2s;
			}
		}
	}
}

/* right line */
.main-menu .nav-item.active:after,
.main-menu .nav-item:hover:after {
	transition-delay: 0.3s;
}

.main-menu .nav-item {

	&.active,
	&:hover {

		&:before,
		&::after,
		.nav-link:before,
		.nav-link:after {
			transform: scale3d(1, 1, 1);
		}
	}
}