// Backgrounds and colors classes helper
$themeColours: ("violet": #914491,
    "black": #2A2A2A,
    "black--alt": #4F4F4F,
    "blue": #29B6F6,
    "light-violet": #DE62DE,
    "second": #EBECEE,
    "gray": #D8D8D8,
    "dark-gray": #818181,
    "second-dark": #ACACAC,
    "white": #FFF,
    "pink-light": #EDDFED,
    "red": #ED1C25
);

@each $themeColour,
$i in $themeColours {
    .bg {
        &-#{$themeColour} {
            background-color: $i;
        }
    }

    .cfont,
    .link {
        &-#{$themeColour} {
            color: $i;
        }
    }
}


.slash-separator {
    @include mar-x(.5rem);
}

// Mobile section séparateur
.section-separator {
    @include box(calc(100% - 30px), 1px);
    margin: 2rem auto;
    background-color: var(--blue);
}

.spacing {
    @include pad-y(3rem);

    @include media-breakpoint-up(lg) {
        @include pad-y(5rem);
    }
}

.header-height-space {
    padding-top: calc(60px + 18px);
    @include media-breakpoint-up (md) {
        padding-top: calc(90px + 50px);
    }
}

.bordered {
    border: 3px solid var(--white);
}

.upper {
	text-transform: uppercase;
}
.icon-left {
    &:before {
        content: "";
        display: inline-block;
        @include mar-x(rem-calc(3.5), .75rem);
        vertical-align: rem-calc(2);
        border-top: rem-calc(2) solid;
        border-right: rem-calc(2) solid;
        border-left: 0;
        border-bottom: 0;
        @include box(rem-calc(7));
		transition: transform .3s ease;
        transform: rotate(45deg) translate(-50%, 50%);
    }

    // Arrow direction to left
    &.dtl {
        &:before {
            transform: rotate(225deg) translate(50%, -50%);
            margin-right: rem-calc(3.5);
        }
    }
}

.icon-right {
    &:after {
        content: "";
        display: inline-block;
        @include mar-x(.75rem, 0);
        vertical-align: rem-calc(2);
        border-top: rem-calc(2) solid;
        border-right: rem-calc(2) solid;
        border-left: 0;
        border-bottom: 0;
        @include box(rem-calc(7));
        transform: rotate(45deg) translate(-50%, 50%);
    }

    // Arrow direction to left
    &.dtl {
        &:before {
            transform: rotate(225deg) translate(50%, -50%);
            margin-right: rem-calc(3.5);
        }
    }
}

.label-page {
    position: absolute;
    top: 50%;
    left: 0;
    min-width: rem-calc(250);
    padding: 1rem 2.5rem;
    background-color: rgba($blue, .8);
    z-index: 10;
    border-bottom: 4px solid var(--violet);
    text-transform: uppercase;
    text-align: center;
    color: var(--white);
    @include media-breakpoint-up(lg) {
        font-size: $fs-h1;
    }

    @include media-breakpoint-down(sm) {
        left: 50%;
        top: 100%;
        min-width: rem-calc(180);
        padding: .6rem 1rem;
        transform: translate(-50%, -50%);
    }
}

.container-share {
    display: none;
}

.dynamic-content {
  margin-bottom: 3rem;
  h1 {
    text-align: center;
    position: relative;
    color: var(--violet);
    ~ h2, ~ h3 {
      font-size: 1.5rem;
      margin-bottom: 1rem;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 400;
    }
  }
  @include media-breakpoint-down( sm ) {
    p {
        text-align: justify;
    }
  }
}
@include media-breakpoint-up(lg) {
    .cta-container {
        overflow: hidden;

        [class^="btn-"].middle-line-right,
        [class^="btn-"].middle-line-left {
            position: relative;

            &:before {
                position: absolute;
                content: "";
                top: 50%;
                right: calc(-1000% + -1rem);
                height: 1px;
                width: 1000%;
                background-color: inherit;
            }
        }

        [class^="btn-"] {
            &.middle-line-right {

                &:before {
                    right: calc(-1000% + -1rem);
                }
            }

            &.middle-line-left {
                &:before {
                    left: calc(-1000% + -1rem);
                }
            }
        }
    }
    .dynamic-content {
      h1 {
        display: inline-block;
        &:before, &:after {
          position: absolute;
          top: 50%;
          content: "";
          @include box(100px, 2px);
          background-color: var(--second);
        }
        &:before {
          left: -116px;
        }
        &:after {
          right: -116px;
        }
      }
    }
    .title-container {
        text-align: center;
        &.text-left {
            margin-left: 2.5rem;
        }
        .middle-line {

            &-left,
            &-right,
            &-center {
                position: relative;
                display: inline-block;
                &.ml-gray {
                    &::after, &::before {
                        background-color: var(--gray);
                    }
                }
                &:before {
                    position: absolute;
                    top: 50%;
                    content: "";
                    @include box(100px, 2px);
                    background-color: var(--gray);
                }
            }

            &-center {
                &:after {
                    position: absolute;
                    top: 50%;
                    content: "";
                    @include box(100px, 2px);
                    background-color: var(--second);
                }

                &:before {
                    left: -116px;
                }

                &:after {
                    right: -116px;
                }
            }

            &-left {
                &:before {
                    left: -116px;
                }
            }

            &-right {
                &:before {
                    right: -116px;
                }
            }

            &--violet {
                &:before,
                &:after {
                    background-color: var(--violet);
                }
            }
        }
    }

    .contact-form {
        .middle-line-center:before,
        .middle-line-center:after {
            background-color: var(--gray);
        }
    }

    .container-share {
		display: flex;
		position: absolute;
		left: 0;
		bottom: -2rem;
		width: 100%;
		z-index: 5;

		.btn-rs {
			padding: .5rem .8rem;
		}
    }
}

.alert {
  z-index: 1000;

  &-info {
      background-color: rgba($blue, .2);
      color: var(--blue);
      border-color: rgba($blue, .4);
  }
}

.mx-900 {
    max-width: rem-calc(900);
}

@include media-breakpoint-down(sm) {
    .py-sm-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}
@include media-breakpoint-down(xs) {
    .pb-xs-0 {
        padding-bottom: 0 !important;
    }
}

.maxw-lg-95, .img-container-maxw-lg-95 .image-container {
    @include media-breakpoint-up( lg ) {
        max-width: 95%;
    }
}

@include media-breakpoint-up( xl ) {
    .pr-xl-6 {
        padding-right: rem-calc( 96 ) !important;
    }
    .pr-xl-7 {
        padding-right: rem-calc( 144 ) !important;
    }
}

// Custom iframe créé dans le wysiwyg
.is-iframe-container {
    display: block;
    iframe {
        width: 100%;
        max-width: 100%;
        min-height: rem-calc( 280 );
        border: none;
    }
  }