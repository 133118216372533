/*****************
 * Base shared styles *
******************/
.main-header {
    .main-logo {
        width: 100%;
    }
}

// Main menu
.navbar-nav {
    @include media-breakpoint-up( lg ) {
        align-items: flex-start;
    } 
    .nav-link {
        text-transform: uppercase;
    }

}

.shop-link {
    .shop-counter {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        padding: 0 6px;
        border-radius: 20px;
        font-size: 0.8rem;
        text-align: center;
        color: var(--white);
        background: var(--violet);
        transform: translateX(25px);
        font-weight: 500;

        ~ .icon {
            margin-right: 1rem;
        }
    }
    &--mobile {
        padding-right: 0;
        
        .shop-counter {
            left: 10px
        }

        .icon.shop-bag {
            @include box(rem-calc(25));
        }
    }
}

.header-menu {
    display: flex;
    flex-direction: column;

    .main-menu {
        .nav-link {
            font-weight: 500;
			font-size: $fs-menu-links;
            @media screen and ( min-width: 992px) and (max-width: 1279.98px ) {
                font-size: $fs-menu-links-sm;
            }
        }
    }

    .secondary-menu {
        .nav-link {
            font-weight: 300;
        }
    }

    .langs-menu {
        display: flex;
        align-items: center;
    }
}

/**********************************
 * Mobile styles (medium to down) *
***********************************/
@include media-breakpoint-down(md) {
    .main-header {
        .navbar-brand {
            max-width: rem-calc(198);
        }

        .main-logo {
            height: rem-calc(42);
        }
    }

    .header-menu {
        position: fixed;
        right: 0;
        top: 0;
        @include box(100%);
        padding: 3rem;
        transition: transform .4s ease-in;
        transform: translateX(100%);
        background-color: var(--second);

        &.show {
            transform: translateX(0);
            overflow: auto;
        }

        .main-menu {
            padding-bottom: .5rem;
            margin-bottom: .5rem;
            border-bottom: 1px solid var(--black);
        }

        .secondary-menu {
            order: 2;
        }

        .langs-menu {
            padding-top: .5rem;
            margin-top: .5rem;
            border-top: 1px solid var(--black);
        }
    }
}

/*****************
 * Desktop styles *
******************/

@include media-breakpoint-up(lg) {
    .main-header {
        .navbar-brand {
            max-width: rem-calc(245);
            padding-bottom: .75rem;
        }

        .main-logo {
            height: rem-calc(35);
        }
    }

    .main-header {
        .navbar {
            align-items: flex-end;
            justify-content: space-between;
        }
    }

    .header-menu {
        align-items: flex-end;

        .secondary-menu {
            .nav-link {
                font-size: $fs-secondary-menu;
            }
        }

        .nav-link {
            @include pad-x(1rem);
            display: flex;
            align-items: center;

            .shop-bag,
            .swiss-flag,
            .user-icon {
                margin-left: rem-calc(3);
            }
        }
    }

}

@include media-breakpoint-up(xl) {
    .main-menu .nav-link {
			padding-left: 1rem !important;
			padding-right: 1rem !important;
    }
}