.countdown {
    color: var( --white );
    ul {
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0;
    }
    li {
        list-style: none;
        color: var( --white );
        text-align: center;
        span {
            line-height: 1.1;
            display: block;
            font-weight: 400;
            &.countdown__number {
                font-weight: 700;
                font-size: rem-calc( 25 );
                @include media-breakpoint-down( md ) {
                    font-size: rem-calc( 20 );
                    
                }
            }
        }
        .divider {
            @include box( rem-calc( 5 ) );
            @include mar-x( rem-calc( 15 ) );
            margin-top: rem-calc( -12 );
            background-color: var( --white );
            border-radius: 50%;

            @include media-breakpoint-down( sm ) {
                @include mar-x( rem-calc( 3 ) );
            }
        }
    }
}