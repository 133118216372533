.btn:focus,
button:focus,
.btn:active,
button:active {
    box-shadow: none;
    outline: none;
}

// style de base pour tous les btns
[class^="btn"] {
    @include pad-x(1.5rem);
    font-size: $fs-button;
    border: rem-calc(1) solid transparent;
    line-height: 1.75;
    text-transform: uppercase;
    letter-spacing: 1px;


    // xs to down
    @media screen and (max-width: 320px) {
            @include pad-x(1rem);
            line-height: 1.5;
            font-size: .8rem;
	}

	&.disabled {
		cursor: not-allowed;
		pointer-events: none;
		background-color: var( --gray );
        opacity: 1;
	}
}

.btn, a.btn {

	&-static {
		color: #fff;
		background-color: inherit;
		&:hover {
            color: #fff;
			background-color: inherit;
        }
	}
    // Primary btn
    &-violet {
        background-color: var(--violet);
        color: var(--white);

        &:hover {
            background-color: var(--violet--hover);
            color: var(--white);
        }
    }

    // Secondary btn
    &-blue {
        background-color: var(--blue);
        color: var(--white);

        &:hover {
			background-color: lighten(#29B6F6, 10);
			color: var(--white);
        }
    }

    // tertiary btn
    &-second {
        background-color: var(--second);
        color: var(--black--alt);

        &:hover {
			background-color: darken(#EBECEE, 10);
			color: var(--black--alt);
        }
    }

    // Primary alt
    &-violet--alt {
        background-color: var(--violet--hover);
        color: var(--white);

        &:hover {
            background-color: lighten(#DE62DE, 10);
        }
    }

    // Btns pour les reseaux sociaux
    &-rs:hover {
        .rs-icon {
            fill: var(--violet--hover);
            &.fill-color {
                &--white {
                    fill: var( --white )
                }
            }
        }
    }
}

a[class^="btn"] {
    @include pad-y(.5rem);
    display: inline-block;
}




// BTN POUR FERMER LES MODALS, MENU, ETC
.button-close {
    position: relative;
    @include box(rem-calc(22));
    border-radius: 0;

    &:before,
    &:after {
        content: "";
        position: absolute;
        background-color: var(--white);
        transform: rotate(45deg);
    }

    &:before {
        top: 0;
        left: 50%;
        @include box(rem-calc(1), 100%);
    }

    &:after {
        top: 50%;
        left: 0;
        @include box(100%, rem-calc(1));
    }
}

// BTN BACK TO TOP
.back-to-top {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: rem-calc(400);
    right: 0;
    @include box(rem-calc(50));
    padding: 0;
    opacity: 0;
    visibility: hidden;
    z-index: 1000;
    transition: background-color .3s, opacity .5s, visibility .5s;

    .icon {
        @include box(rem-calc(25));
        transform: rotate(180deg);
    }

    &:hover {
        background-color: var(--light-brown);
    }

    &.show {
        opacity: 1;
        visibility: visible;
    }
}



// Check buttons
.check-invisible {
    display: none;

    &:checked {

        ~label {

            .icon {
            }
        }
    }
}

// Custom dropdown toggle btn
.dropdown-toggle {
    display: flex;
    align-items: center;

    &:after {
        content: url("~Images/icons/dropdown-arrow.svg");
        border: none;
        width: rem-calc(11);
        margin-left: rem-calc(5);
    }
}


// Main button in mobile
.main-cta {
    @include media-breakpoint-down(sm) {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: $z-super;
        visibility: hidden;
        opacity: 0;
        transform: translateY(100%);
        transition: visibility .5s ease, opacity .85s ease, transform .5s ease;

        &.sticky {
            visibility: visible;
            opacity: 1;
            transform: translateY(0);
            padding-bottom: 0;
            padding-bottom: calc(2 * env(safe-area-inset-bottom));
            background-color: var(--white);
            padding-top: 0;

        }
        &, button, a {
            width: 100%;
            @include pad-y(.75rem);
        }
        a {
            text-align: center;
            line-height: 1.75;
        }
    }

}
