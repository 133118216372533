$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: 576px,
  // Medium screen / tablet
  md: 768px,
  // Large screen / desktop
  lg: 992px,
  // Extra large screen / wide desktop
  xl: 1280px
);

$container-max-widths: (
  sm: 540px,
  md: 738px,
  lg: 960px,
  xl: 1220px
);

@import "~bootstrap/scss/bootstrap.scss";

/*
This boilerplate is based in ITCSS and SMACSS
*/

// Config
@import "config/mixins";
@import "config/functions";
@import "config/variables";

// Base and elements (generic)
@import "base/base";

// LAYOUT
@import "layout/layout";
@import "pages/pages";

// MODULES (SMACSS) OR COMPONENTS (ITCSS)
// UI elements (butons, tables, menus, etc).
//@import "components/buttons";
@import "components/buttons/buttons";
@import "components/spinner/spinner";
@import "components/menus/menus";
@import "components/dropdowns/dropdown";
@import "components/banners/main-banner";
@import "components/carousel/carousel";
@import "components/forms/forms";
@import "components/cards/cards";
@import "components/modals/modals";
@import "components/collapses/collapse";
@import "components/tables/tables";
@import "components/maps/maps";
@import "components/countdowns/countdown";
@import "components/counters/counter";
@import "components/parallax/parallax";
@import "components/switches/switch";
@import "animations/animations";

// Theme (colors and fonts, icons)
@import "theme/typography";
@import "theme/icons";


// Helpers
@import "helpers/helpers";

// Plugins
@import "aos/dist/aos.css"