// large screen to up
@include media-breakpoint-up(lg) {
    .page-contact {
        .container {
            position: relative;
            svg.k-icon {
                position: absolute;
                @include box (65%, calc(100% + 11rem)); // 11rem = 5rem padd-top et 5rem padd-bottom + 1rem pour ne pas afficher les bordures
                transform: translateY(-5rem);
                top: 0;
                left: -5rem;
            }
        }
    }
}