@import "header";
@import "footer";


body {
  min-height: 100vh;
  flex-wrap: wrap;
}

main,
body {
  display: flex;
}

main {
  flex-direction: column;
}

footer {
  background-color: var(--second);
}

header,
footer,
article,
section {
  width: 100%;
}

// Order les elemens
.banner-section {
  order: -2;
}

.strengths-section:not(.strengths-booking),
.block-intro.homepage,
.block-intro.clinics,
.block-intro.intro-treatment,
.block-intro.intro-technology,
.services-choice,
.testimonials,
.technologies-section,
.resources-section,
.engagement-section,
.has-container-custom {
  @include pad-x(1rem);

  .container {
    max-width: 100%;
  }
}

@include media-breakpoint-up(lg) {


  .block-intro.homepage,
  .block-intro.clinics {
    @include pad-x(15px);

    .block-intro-image {
      &::before {
        width: 155%;
      }
    }
  }

  .carousel.homepage {
    position: static;

    .carousel-indicators {
      right: calc(50px - 1rem);
      transform: translateY(50%);
    }
  }
}

@include media-breakpoint-up(xl) {

  .block-intro.homepage,
  .block-intro.clinics,
  .block-intro.intro-technology {
    @include pad-x(100px);
    overflow: hidden;

    .block-intro-image {
      &::before {
        width: 155%;
      }
    }
  }

  .block-intro.intro-treatment {
    @include pad-x(0);

    .treatment-text-content {
      p {
        padding-right: 1rem;
      }
    }

    .images-treatment {
      img {
        width: 65%;
      }
    }
  }


  .technologies-section,
  .resources-section,
  .engagement-section {
    @include pad-x(100px, 0);

  }

  .engagement-section,
  .services-choice,
  .technologies-section,
  .has-container-custom {
    @include pad-x(100px);

  }

}

.resources-section .col-md-6:last-child,
.engagement-section .image-container {
  padding-right: 0;
}

.resources-section {
  padding-right: 0;
  @include media-breakpoint-down( sm ) {
    &:not( .sideblocks-section ) .dynamic-content {
      padding-right: 15px;
    }
  }
}

.testimonials {
  @include media-breakpoint-up(lg) {

    &,
    .container {
      @include pad-x(0);
    }
  }
}

.services-choice {
  @include media-breakpoint-up(lg) {
    p.text-lg-center {
      @include pad-x(20%);
    }
  }

  .carousel-accordion .carousel-control-prev {
    transform: translate(0%, -60%);
  }

  .carousel-accordion .carousel-control-next {
    transform: translate(100%, -60%);
  }
}

@media screen and (min-width: 1400px) {
  section.strengths-section:not(.strengths-booking) .strength {
    @include box(calc(984px / 4));
  }

  .engagement-section {
    .engagement-list {
      li {
        font-size: $fs-p;
      }
    }
  }
}
@media screen and (min-width: 1900px) {
  section.strengths-section:not(.strengths-booking) .strength {
    @include box(280px);
  }
}
