table,
.table {
    border-collapse: separate;
    border-spacing: 8px 8px;

    thead,
    thead th,
    tr,
    tr td {
        border: none;
    }

    thead {
        tr:first-child {
            th {
                @include pad-y(.4rem);
            }
        }
    }

    td:not(.fc-daygrid-day) {
        width: calc((1140px - 30px - (6px * 4)) / 4);  // 1140px max-width du .container - 30px de padd-x du .container - (6px border-spacing axe X des td * 4 colonnes) / 4 colonnes

        @include media-breakpoint-down(sm) {
            width: 50%;
        }
    }

    &.table-clinics {
      td {
        width: calc(100% / 4);
      }
    }


    th,
    td {
        padding: .5rem 1.5rem;
        vertical-align: middle;

        &:not([scope="row"]) {
            text-align: center;
        }
    }
    th:first-child {
        text-align: left;
    }
    th,
    td[scope="row"] {
        font-weight: 500;
	}
    td[scope="row"] {
        @include media-breakpoint-up( md ) {
            min-width: rem-calc( 190 );
        }
	}

	&.table-comparative  {
		thead {
			tr:first-child {
				th {
					@include pad-y(.8rem);
				}
			}
		}
		td, th {
			@include pad-y(.8rem);
            @include media-breakpoint-down(sm) {
                @include pad-x(.6rem);
            }
		}

        @include media-breakpoint-down(sm) {
            border-collapse: collapse;
            tr:first-child {
                th {
                    border-bottom: 8px solid #fff;
                }
            }
            tbody {
                tr:first-child td {
                    font-weight: 500;
                    vertical-align: middle;
                }
            }
            td {
                border-color: inherit;
                border-bottom-width: 0;
                border-top-width: 0;
                border-right: 2px solid #fff;
                vertical-align: top;
                small {
                    text-transform: uppercase;
                }
            }
            &.two-columns-highlighted {
                // quand la colonne diode activée alors mettre child(2)
                td:nth-child(1), .table-header-secondary th:first-child {
                    // border-right: 8px solid #fff; //seulement quand il y a qu'une colonne violet
                    border-right: 2px solid #fff; //Quand il y a deux colonnes violet
                }
            }
            .table-header-secondary {
                th {
                    border-bottom: 2px solid #fff;
                    font-size: 8px;
                }
            }
            .bg-second {
                background-color: #E6E6E6;
                color: #666666;
                &-dark {
                    background-color: #999999;
                }
            }
            td, td p {
                font-size: 8.5px;
            }
            td p {
                font-weight: 500;
            }
        }
	}

    @include media-breakpoint-down(md) {
        &.first-col-fixed {
            td, th {
                &:first-child {
                    position: sticky;
                    left: 0;
                    box-shadow: 0 0 0 1px var(--white);
                }
            }
        }
    }

    &.vertical-middle {
        th, td {
            vertical-align: middle;
            @include media-breakpoint-down( sm ) {
                font-size: 13px;
            }
        }
    }
}
.table-spacing-2 {
    border-spacing: 2px;
}
.table-dynamic {
    td {
        font-size: rem-calc( 15 );
        &:nth-child( 3 ),
        &:nth-child( 5 ) {
            font-weight: 500;
        }
    }
}

.dynamic-content {
    max-width: 100%;
    overflow: auto;
    table {
        border-collapse: collapse;
        border-spacing: 0;
        border: none;
        background-color: var( --blue );
    }
    thead {
        color: var( --white );
        background-color: var( --violet );
        tr:first-child {
            th {
                padding-bottom: .4rem;
                padding-top: .4rem;
                &, > * {
                    color: var( --white );
                    text-transform: uppercase;
                    text-align: center;
                    margin-bottom: 0;
                    font-weight: 400;

                    @include media-breakpoint-down( sm ) {
                        font-size: rem-calc( 11 );
                        min-width: 104px;
                    }
                }
            }
        }
    }
    th:first-child {
        border-left: 3px solid var(--violet);
    }
    th:last-child {
        border-right: 3px solid var(--violet);
    }
    td:first-child {
        border-left: 3px solid var(--blue);
    }
    td:last-child {
        border-right: 3px solid var(--blue);
    }
    th, td {
        border-bottom: 3px solid var( --blue );
        border-left: 3px solid var( --blue );
        border-right: 3px solid var( --blue );
        font-size: 1em;


        @include media-breakpoint-down( sm ) {
            border-bottom-width: 2px;
            border-left-width: 2px;
            border-right-width: 2px;
            font-size: rem-calc( 11 );
            padding-left: 5px;
            padding-right: 5px;
            min-width: 70px;
        }
    }
    tbody {
        background-color: var( --white );
        tr:hover>td,
        tr:hover>th {
            background-color: hsla(0, 0%, 50.2%, .1);
        }
    }
}