@import "login_register_form";
@import "newsletter_form";
@import "animated_form";


form,
.form {
    padding: 2rem 1rem;
    label.required {
        &:after {
            content: "*";
            position: relative;
            top: -3px;
            left: 2px;
            font-size: 80%;
        }
    }
    &.contact-form {

        .form-conditions,
        .form-submit {
            text-align: left;
        }

        button[type="submit"] {
            width: 100%;
            text-align: center;
        }
    }

    .row {
        justify-content: center;
    }

    &-message {
        height: calc(100% - 1rem);

        textarea {
            height: 100%;
        }
    }

    .invalid-feedback {
        position: absolute;
        top: 2.85rem;
        left: 5px;
    }
    // Fix overflow de google -recaptcha sur les xsmall devices
    @media screen and (max-width: rem-calc(374.98)) {
        .g-recaptcha {
            transform: scale(0.77);
            transform-origin: 0 0;
        }
    }

    @include media-breakpoint-up(md) {

        &-submit,
        .subtitle,
        .form-conditions {
            text-align: center;
        }

        &-recapcha {
            display: flex;
        }
    }
}


.page-contact, .page-contact-press, .sign-section, .account-section {
    form {
        .form-group .form-control {

            border: 1px solid var(--gray);
        }
    }
}
@include media-breakpoint-down(md) {

	.page-contact-press {
		.contact-form-section.spacing {
			padding-top: 0;
		}
	}
}