// mixin to give dimensions to a box
@mixin box($width, $height: $width) {
    width: $width;
    height: $height;
}

// mixin to give horizontal padding
@mixin pad-x($left, $right: $left) {
    padding-left: $left;
    padding-right: $right;
}
// mixin to give vertical padding
@mixin pad-y($top, $bottom: $top) {
    padding-top: $top;
    padding-bottom: $bottom;
}

// mixin to give horizontal margin
@mixin mar-x($left, $right: $left) {
    margin-left: $left;
    margin-right: $right;
}
// mixin to give vertical margin
@mixin mar-y($top, $bottom: $top) {
    margin-top: $top;
    margin-bottom: $bottom;
}