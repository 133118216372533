.prestation-type-switch-container {
    gap: 10px;
}
.techno-switch-label {
    font-size: rem-calc( 22 );
}
.prestation-type-switch {
    position: relative;
    background-color: var( --violet );
    color: var( --white );
    transition: background-color .35s linear;
    font-size: rem-calc( 17 );
    font-weight: 500;
    text-transform: uppercase;
    @include media-breakpoint-down(sm) {
        // width: 50%;
    }
    &:after {
        content: "";
        position: absolute;
        bottom: 5px;
        right: 50%;
        width: 12px;
        height: 12px;
        opacity: 0;
        transform: rotate(45deg) translate(50%, 0%);
        background-color: inherit;
        transition: bottom .35s ease;
    }
    &.active {
        background-color: var( --second );
        color: var( --violet );
    }
    &:active,
    &.active,
    &:focus,
    &:hover {
        &:after {
            bottom: -2px;
            opacity: 1;
        }
    }
}