.map-section {
    #map {
        @include box(100%, rem-calc(600));
    }
}






/**
 * GOOGLE MAP MARKERS AND TOOLTIPS CUSTOM STYLES
 */
// Tooltip container
.gm-style .gm-style-iw-c {
    padding: 1rem !important; // Important pour annuler les styles par défaut donné par google maps avec js
    border-radius: 0;
    overflow-y: auto;

    //Titre du tooltip
    .firstHeading {
        font-size: $fs-button;
        margin-bottom: rem-calc(10);
        color: var(--medium-brown);
    }
    &::-webkit-scrollbar {
        display: none;
    }

    /* IE and Edge */
    -ms-overflow-style: none;
    /* Firefox */
    scrollbar-width: none;
}
