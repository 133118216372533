.counter {
    flex-wrap: wrap;
    gap: 10px;
    line-height: 1;
    &, &__value {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__prefix {
        font-size: 18px;
        color: var( --secondary );
    }
    &__number,
    &__suffix {
        color: var( --violet );
    }
    &__number {
        font-size: 56px;
        font-weight: 700;
        margin-right: 10px;
    }
    &__suffix {
        font-size: 40px;
    }
    &__label {
        color: var( --blue );
        font-size: 22px;
        width: 100%;
        text-align: center;
    }
}