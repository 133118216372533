.main-header {
    position: fixed;
	top: 0;
	left: 0;
    height: rem-calc(60);
    z-index: $z-superx20;
    background-color: var(--second);
    transition: background-color .85s ease;
    .navbar {
        @include pad-x(0);
    }
    // Burger btn
    .navbar-toggler {
        padding: 0;
    }
    // close btn
    .close {
        position: fixed;
        top: 1rem;
        right: 1rem;
    }

    &.has-banner-sales {
        @include media-breakpoint-down( lg ) {
            height: auto;
        }
    }
}




@include media-breakpoint-up(lg) {
    .main-header {
        background-color: transparent;
        height: fit-content;
        &.sticky {
            background-color: var(--white);
        }
    }
}


.banner-sales-section {
    background-color: var( --violet );
}
.banner-sales {
    gap: rem-calc( 25 );
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: rem-calc( 20 ) 0 0;
    &__container-info {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        width: 100%;
        justify-content: center;
        gap: rem-calc( 20 );
        @include media-breakpoint-up( md ) {
            flex-direction: column;
            width: auto;
            gap: rem-calc( 10 )
        }
        @include media-breakpoint-up( lg ) {
            flex-direction: row;
            gap: rem-calc( 20 )
        }
    }
    &__text-content {
        text-align: center;
        p {
            line-height: 1.2;
            text-transform: uppercase;
            margin-bottom: 0;
            &.banner-sales__name {
                font-weight: 700;
                font-size: rem-calc( 25 );
            }
            &.banner-sales__description {
                font-weight: 400;
            }
        }

        @include media-breakpoint-down( sm ) {
            display: flex;
            align-items: center;
            gap: rem-calc( 5 );
            p.banner-sales__name,
            p.banner-sales__description {
                font-size: rem-calc( 15 );
            }
        }
    }
    @include media-breakpoint-up( md ) {
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: rem-calc( 20 ) 0;
        gap: rem-calc( 50 );
        &.countdown-active {
            justify-content: space-between;
            gap: rem-calc( 20 );
        }
    }
    @include media-breakpoint-up( lg ) {
        &.countdown-active {
            gap: 0;
        }
    }
    @include media-breakpoint-up( lg ) {
        padding: rem-calc( 20 ) rem-calc( 30 );
    }

    .banner-sales__image {
        max-width: rem-calc( 90 );
        min-width: rem-calc( 80 );
    }

    &__name,
    &__description {
        color: var( --white );
    }
  }


  .banner-secondary {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      @include media-breakpoint-down( sm ) {
            background-position: 10% 0;
            padding-bottom: 0;
            .col-12 {
                background-image: linear-gradient(-25deg, rgba(255, 255, 255, 0.6) 30%, transparent);
            }
        }
    &__content {
        min-height: 100vh;
        display: flex;
        align-items: flex-end;
        margin-left: auto;
        padding: rem-calc( 10 );
        @include media-breakpoint-up( sm ) {
            align-items: center;
            min-height: 70vh;
            max-width: 50%;
            padding-top: rem-calc( 50 );
        }
    }

    .banner {
        @include media-breakpoint-down( sm ) {
            &__subtitle {
                font-size: rem-calc( 22 );
                text-align: center;
            }
            &__text {
                font-size: rem-calc( 18 );
                text-align: justify;
            }
        }
    }
  }