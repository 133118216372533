/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  display:swap;
  font-style: normal;
  font-weight: 300;
  src: url('../../fonts/roboto-v20-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../../fonts/roboto-v20-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/roboto-v20-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/roboto-v20-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/roboto-v20-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/roboto-v20-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  display:swap;
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/roboto-v20-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../../fonts/roboto-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/roboto-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/roboto-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/roboto-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/roboto-v20-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  display:swap;
  font-style: normal;
  font-weight: 500;
  src: url('../../fonts/roboto-v20-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../../fonts/roboto-v20-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/roboto-v20-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/roboto-v20-latin-500.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/roboto-v20-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/roboto-v20-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  display:swap;
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/roboto-v20-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../../fonts/roboto-v20-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/roboto-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/roboto-v20-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/roboto-v20-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/roboto-v20-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}


body {
    font-family: $ff-main, $ff-sans-serif;
}

h1,
.h1,
.title {
    font-size: $fs-h1;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0;
    @include media-breakpoint-down( sm ) {
        font-size: $fs-h1-sm;
        text-align: center;
    }
}

h2,
.h2 {
    font-size: $fs-h2;
    margin-bottom: 1rem;
}

.subtitle {
    font-size: $fs-subtitle;
    margin-bottom: 1rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 400;

    @include media-breakpoint-down(sm) {
        font-size: rem-calc(21);
        text-align: center;
    }
}

p {
    font-size: $fs-p;
}

.footer-title {
    font-size: $footer-title;

    &--small {
        font-size: $footer-title--sm;
    }
}

.list__link {
    font-size: rem-calc(18);
}

.fw-normal {
    font-weight: normal;
}