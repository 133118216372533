.banner-section {
  padding-bottom: 3rem;

  &.common-banner {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 85% 100%;
    min-height: 90vh;
    padding-bottom: 0;

    @include media-breakpoint-down(sm) {
      margin-bottom: 3rem;

      &.banner-contact {
        background-position: center;
      }
    }

    @media only screen and (max-width: 767.98px) and (orientation : portrait) {
      min-height: 40vh;

      .container {
        min-height: 40vh;
      }
    }

    @media only screen and (max-width: 450px) and (max-height: 812px) and (orientation : portrait) {

      &.banner-reservation {

        &,
        .container {
          min-height: 0;
        }
      }
    }

    .container {
      position: relative;
      height: 100%;
    }

    @include media-breakpoint-up(md) {
      padding-bottom: 3rem;
      max-height: 1200px;
      background-position: right bottom;

      &:not(.banner-reservation) {

        .label-page {
          left: -4rem;
          @include pad-x(calc(4rem + 15px));
          @include pad-y(2rem);
        }
      }

      &.banner-reservation,
      &.banner-clinics {
        min-height: 415px;
        height: auto;
      }
    }

    @include media-breakpoint-up(lg) {
      height: 70vh;
      min-height: 0;
    }

    @media screen and (min-width: 1024px) and (max-height: 1400px) and (orientation: portrait) {
      height: 40vh;
    }

    @media screen and (min-width: rem-calc(3000)) {
      max-height: 600px;

      .container {
        min-height: 0;
      }
    }

    @media screen and (min-width: 768px) and (max-width: 1023.98px) and (orientation : portrait) {
      min-height: 40vh;
      height: 40vh;
    }

    @media screen and (min-width: 1200px) and (max-width: 1480px) {
      min-height: 500px;
    }

    &.banner-page {
      background-position: center 30%;
      @media screen and ( min-width: 992px ) {
        height: 75vh;
      }
      &:after {
        content: "";
        background-image: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF00 35%);
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
      }
    }
  }

  // Banner avec un border right bottom
  &.banner-br-border {
    position: relative;
    @include media-breakpoint-up(md) {
      height: 70vh;
    }
    @include media-breakpoint-up(lg) {
      &:before {
        content: "";
        position: absolute;
        top: calc(100% - 6px);
        right: 0;
        width: 50%;
        border-top: 6px solid var(--violet);
        z-index: 1;
      }
    }
    picture img {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 100%;
    }
  }


  &.banner-clinics,
  &.banner-reservation {
    padding-top: rem-calc(60);

    .container,
    & {
      position: relative;
    }

    .container {
      @include media-breakpoint-down(sm) {
        @include pad-x(0);
      }
    }

    @include media-breakpoint-up(md) {

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        @include box(100%, calc(100% - 90px - 3rem));
        background-image: linear-gradient(0deg, var(--second-light), var(--second));
      }

      .label-page {
        top: 100%;
        left: 0;
        transform: translateY(-50%);
      }
    }

    @include media-breakpoint-up(lg) {
      padding-top: 89px; // 89px height du header en version desktop

      .container-share {
        justify-content: flex-end;
        padding: 2px 1rem;
        bottom: 0;
        background-color: rgba($second, .5);
      }
    }
  }

  &.banner-clinics,
  &.single-clinic {
    @include media-breakpoint-up(md) {
      &:before {
        width: 100%;
      }
    }
  }

  &.contact-press {
    position: relative;

    >.container {
      position: absolute;
      height: 100%;
      transform: translateX(-50%);
      left: 50%;
    }

    .label-page {
      transform: translateY(-50%);
    }

    .container-share {
      bottom: 0;
    }

    img {
      height: 70vh;
      object-fit: cover;
    }

    @media screen and (min-width: 0) and (max-width: 1024px) and (orientation : portrait) {
      max-height: 50vh;

      img {
        height: 50vh;
        object-fit: cover;
      }
    }

    @media screen and (min-width: 0) and (max-width: 1023.98px) and (orientation : landscape) {

      &,
      img {

        height: 100vh;
      }
    }

    @include media-breakpoint-down(sm) {
      .label-page {
        transform: translate(-50%, -50%)
      }
    }
  }
}